import MainLayout from "../../components/Layouts";
import PaymentDetailsList from "../../components/Payment/Details";
import React from "react";

const PaymentDetailsPage = () => {
  return (
    <MainLayout>
      <PaymentDetailsList />
    </MainLayout>
  );
};

export default PaymentDetailsPage;
