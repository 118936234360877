import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import axios from "axios";
import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Avatar,
  Button,
  Empty,
  Switch,
  Form,
  DatePicker,
  Select,
  AutoComplete,
  Spin,
  Input,
  Drawer,
} from "antd";

import BreadCrumb from "../Layouts/Breadcrumb";
import { responseNotification } from "../../utils/notify";
import { getPage, getParamValue } from "../../utils";
import { useLocation, useNavigate } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import moment from "moment";
import React from "react";
import { AddBtn, Loader, Pagination } from "../common";
import { Console } from "console";
import { Link } from "react-router-dom";
import { debounce } from "lodash";
import SinglePayment from "./Details";
const { RangePicker } = DatePicker;
const PaymentHistoryList = (): ReactElement => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const limit = getParamValue(loc.search, "limit");
  const [sortBy, setSortBy] = useState<any>("createdAt");
  const [sortOrder, setSortOrder] = useState<any>("DESC");
  const [brandId, setBrandId] = useState<any>();
  const [selectedTransaction, setSelectedTransaction] = useState(undefined);
  //const limit = 16;
  const [showSearch, setShowSearch] = useState(true);
  const [visible, setVisible] = useState<any>(undefined);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [range, setRange] = useState<any>({
    from: null,
    to: null,
  });

  const [paymentData, setPaymentData] = useState<any>({
    loading: false,
    data: null,
  });
  const [orderOptions, setOrderOptions] = useState({
    loading: false,
    list: [],
  });
  const [transactionOptions, setTransactionOptions] = useState({
    loading: false,
    list: [],
  });

  const getPaymentHistory = useCallback(async () => {
    const from = moment(range.from)?.toISOString();
    const to = moment(range.to)?.toISOString();
    setPaymentData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_PAY_API}`;
    axios
      .get(
        `${encodedUri}/jc-gateway/my-payments?` +
          (page
            ? `page=${
                page == paymentData?.data?.currentPageNumber ? 0 : page || 0
              }`
            : `page=0`) +
          (limit ? `&limit=${limit}` : `&limit=16`) +
          (orderId ? `&orderId=${orderId}` : ``) +
          (transactionId ? `&transactionId=${transactionId}` : ``) +
          (sortOrder ? `&sortOrder=${sortOrder}` : ``) +
          (sortBy ? `&sortBy=${sortBy}` : ``) +
          (from ? `&fromDate=${from}` : ``) +
          (to ? `&toDate=${to}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setPaymentData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setPaymentData({ loading: false, data: [] });
        console.error("Payment: Error", err);
      });
  }, [page, limit, orderId, transactionId, sortBy, sortOrder, range]);

  const getOrderOptions = useCallback(
    async (getOrderId: any) => {
      setOrderOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_PAY_API}`;
      return axios
        .get(
          `${encodedUri}/jc-gateway/my-payments?page=${page || 0}` +
            (limit ? `&limit=${limit}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setOrderOptions({
            loading: false,
            list: res.data?.transactions?.map((order: { orderId: string }) => ({
              label: `${order?.orderId}`,
              value: order?.orderId,
            })),
          });
        })
        .catch((err) => {
          setOrderOptions({ loading: false, list: [] });
          console.error("orderId: Error", err);
        });
    },
    [limit, page]
  );
  const getTransactionOptions = useCallback(
    async (getOrderId: any) => {
      setTransactionOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_PAY_API}`;
      return axios
        .get(
          `${encodedUri}/jc-gateway/my-payments?page=${page || 0}` +
            (limit ? `&limit=${limit}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setTransactionOptions({
            loading: false,
            list: res.data?.transactions?.map(
              (order: { merchantTransactionId: string }) => ({
                label: `${order?.merchantTransactionId}`,
                value: order?.merchantTransactionId,
              })
            ),
          });
        })
        .catch((err) => {
          setTransactionOptions({ loading: false, list: [] });
          console.error("orderId: Error", err);
        });
    },
    [limit, page]
  );

  const fetchRef = useRef(0);
  const handleSearch = useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "order") getOrderOptions(value);
        if (field === "transaction") getTransactionOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getOrderOptions, getTransactionOptions]);

  useEffect(() => {
    if (showSearch) {
      getOrderOptions("");
      getTransactionOptions("");
    }
  }, [showSearch]);

  useEffect(() => {
    getPaymentHistory();
  }, [getPaymentHistory]);
  const reseAllFieldData = () => {
    form.resetFields();
    setOrderId("");
    setTransactionId("");
    setRange({
      from: null,
      to: null,
    });
  };
  const onClose = () => {
    getPaymentHistory();
    setSelectedTransaction(undefined);
    setVisible(undefined);
  };

  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <>
      <BreadCrumb
        title="My Payment History"
        subTitle={`${
          paymentData?.data?.totalElements ||
          paymentData?.data?.transactions?.length ||
          0
        } Payment(s)`}
      />
      {showSearch && (
        <div className={styles?.searchBox}>
          <Form layout="inline" form={form} className={styles.formInline}>
            <Form.Item name="range">
              <RangePicker
                defaultValue={
                  range.from != null && range.to != null
                    ? [moment(range.from), moment(range.to)]
                    : null
                }
                onChange={(_e, f) => {
                  console.log("momnet", moment.utc(f[0])?.toISOString());
                  setRange({ from: f?.[0], to: f?.[1] });
                }}
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [
                    moment("00:00:00", "HH:mm:ss"),
                    moment("23:59:59", "HH:mm:ss"),
                  ],
                }}
                inputReadOnly
                allowClear
              />
            </Form.Item>

            <Form.Item name="orderId">
              <AutoComplete
                backfill
                allowClear
                onSearch={(e) => handleSearch(e, "order")}
                onSelect={(val: any) => {
                  setOrderId(val);
                }}
                options={orderOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                  orderOptions?.loading ? <Spin size="small" /> : null
                }
                dropdownMatchSelectWidth={335}
              >
                <Input.Search
                  placeholder="Order ID"
                  onSearch={(val) => {
                    setOrderId(val);
                  }}
                  enterButton
                />
              </AutoComplete>
            </Form.Item>
            <Form.Item name="transactionId">
              <AutoComplete
                backfill
                allowClear
                onSearch={(e) => handleSearch(e, "transaction")}
                onSelect={(val: any) => {
                  setTransactionId(val);
                }}
                options={transactionOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                  transactionOptions?.loading ? <Spin size="small" /> : null
                }
                dropdownMatchSelectWidth={335}
              >
                <Input.Search
                  placeholder="Transaction ID"
                  onSearch={(val) => {
                    setTransactionId(val);
                  }}
                  enterButton
                />
              </AutoComplete>
            </Form.Item>

            <Form.Item
              name="orderP_by_search"
              initialValue={
                sortBy == "displayOrder"
                  ? "DISPLAY ORDER"
                  : sortBy == "createdAt"
                  ? "CREATED AT"
                  : sortBy == "updatedAt"
                  ? "UPDATED AT"
                  : "SORTING BY"
              }
            >
              <Select
                showSearch
                placeholder="Status"
                onChange={(val) => setSortBy(val as string)}
              >
                <Select.Option value={undefined}>SORTING BY</Select.Option>
                <Select.Option value={"displayOrder"} title="DISPLAY ORDER">
                  DISPLAY ORDER
                </Select.Option>
                <Select.Option value={"createdAt"} title="CREATED AT">
                  CREATED AT
                </Select.Option>
                <Select.Option value={"updatedAt"} title="UPDATED AT">
                  UPDATED AT
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="sorting_order_search"
              initialValue={
                sortOrder == "ASC"
                  ? "ASC"
                  : sortOrder == "DESC"
                  ? "DESC"
                  : "SORTING ORDER"
              }
            >
              <Select
                showSearch
                placeholder="sorting order"
                onChange={(val) => setSortOrder(val as string)}
              >
                <Select.Option value={undefined}>SORTING ORDER</Select.Option>
                <Select.Option value={"ASC"} title="ASC">
                  ASC
                </Select.Option>
                <Select.Option value={"DESC"} title="DESC">
                  DESC
                </Select.Option>
              </Select>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {paymentData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Order ID</th>
                      <th scope="col">Transaction ID</th>
                      <th scope="col">Reference ID</th>
                      <th scope="col">Status</th>
                      <th scope="col">Updated At</th>
                      <th scope="col">Updated By</th>
                    </tr>
                  </thead>

                  <tbody>
                    {paymentData?.data?.transactions?.length ? (
                      paymentData?.data?.transactions?.map(
                        (payment: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                            onClick={() =>
                              setSelectedTransaction(payment?.paymentRefId)
                            }
                          >
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {payment?.merchant?.name}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {payment?.amount}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {payment?.orderId}
                              </span>
                            </td>

                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {payment?.merchantTransactionId}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {payment?.paymentRefId}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {payment?.status}
                              </span>
                            </td>
                            <td>
                              <small
                                className={`font-capitalize ${
                                  moment().diff(
                                    payment?.createdAt,
                                    "minutes"
                                  ) >= 60
                                    ? "text-red-600"
                                    : "text-gray-800"
                                }`}
                              >
                                {moment(payment?.createdAt).fromNow()}
                              </small>
                              <br />
                              <span className="name">
                                {moment(payment?.createdAt).format("lll")}
                              </span>
                            </td>
                            <td>
                              <small
                                className={`font-capitalize ${
                                  moment().diff(
                                    payment?.updatedAt,
                                    "minutes"
                                  ) >= 60
                                    ? "text-red-600"
                                    : "text-gray-800"
                                }`}
                              >
                                {moment(payment?.updatedAt).fromNow()}
                              </small>
                              <br />
                              <span className="name">
                                {moment(payment?.updatedAt).format("lll")}
                              </span>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...paymentData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>
      <Drawer
        title="Payment Details"
        placement="right"
        onClose={onClose}
        open={selectedTransaction}
        width={450}
      >
        <SinglePayment selectedTransaction={selectedTransaction} />
      </Drawer>
    </>
  );
};

export default PaymentHistoryList;
