import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import NotFound from "../components/common/404";
import Login from "../components/Login/index";
import CompanySignup from "../components/Login/signup";
import React from "react";
import DashboardPage from "../pages";
import MyPaymentHistoryPage from "../pages/payment/index";
import PaymentDetailsPage from "../pages/payment/details";
import MerchantCreatePage from "../pages/merchant/create";
import ChangePasswordPage from "../pages/change-password";
import ProfileUpdatePage from "../pages/profile-update";

const MainRoutes = (): any => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  return (
    <BrowserRouter>
      <Routes>
        {!token ? (
          <>
            <Route path="*" element={<Login />} />
            <Route path={`${process.env.PUBLIC_URL}/`} element={<Login />} />
            <Route
              path={`${process.env.PUBLIC_URL}/signup`}
              element={<CompanySignup />}
            />
          </>
        ) : (
          <>
            <Route path="*" element={<NotFound />} />
            <Route
              path={`${process.env.PUBLIC_URL}/`}
              element={<DashboardPage />}
            />

            <Route
              path={`${process.env.PUBLIC_URL}/payment-history`}
              element={<MyPaymentHistoryPage />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/transaction/:paymentRefId/details`}
              element={<PaymentDetailsPage />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/merchants-create`}
              element={<MerchantCreatePage />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/merchants/:merchantId/edit`}
              element={<MerchantCreatePage />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/profile-update`}
              element={<ProfileUpdatePage />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/change-password`}
              element={<ChangePasswordPage />}
            />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
};
export default MainRoutes;
