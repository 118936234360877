import React from "react";
import MainLayout from "../../components/Layouts";
import AddMerchant from "../../components/Merchant/AddMerchant";

const MrtchantAddPage = () => {
  return (
    <MainLayout>
      <AddMerchant />
    </MainLayout>
  );
};

export default MrtchantAddPage;
