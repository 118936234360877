import MainLayout from "../../components/Layouts";
import PaymentHistoryList from "../../components/Payment/index";
import React from "react";

const PaymentHistoryPage = () => {
  return (
    <MainLayout>
      <PaymentHistoryList />
    </MainLayout>
  );
};

export default PaymentHistoryPage;
