import { Image } from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import { responseNotification } from "../../utils/notify";

const SingleTransaction = ({
  selectedTransaction,
}: {
  selectedTransaction: any;
}): ReactElement => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const [singleTransactionInfo, setSingleTransaction] = useState<any>({
    loading: false,
    data: null,
  });
  const fetchBrandDetails = useCallback((refId: any) => {
    if (refId) {
      try {
        setSingleTransaction({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_PAY_API}/jc-gateway/my-transaction-details?paymentRefId=${refId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setSingleTransaction({
                loading: false,
                data: data?.data?.transactionDetails,
              });
            } else {
              setSingleTransaction({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleTransaction({ loading: false, data: null });

            console.log(err);
          });
      } catch (error) {
        setSingleTransaction({ loading: false, data: null });
      }
    }
  }, []);

  useEffect(() => {
    fetchBrandDetails(selectedTransaction);
  }, [selectedTransaction]);

  const transactionDetails = singleTransactionInfo?.data;
  return (
    <>
      <div className="">
        <div className="flex flex-no-wrap py-2 mt-8">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Transaction ID</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{transactionDetails?.transactionId}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2 mt-8">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Customer Name</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>
                {transactionDetails?.paymentRequest?.cust_info?.cust_name}
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2 mt-8">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Customer Mobile</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>
                {transactionDetails?.paymentRequest?.cust_info?.cust_mobile}
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Status</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{transactionDetails?.status}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Gateway Status</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{transactionDetails?.gatewayStatus}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Amount</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{transactionDetails?.amount}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleTransaction;
