import { Button, Form, Input } from "antd";
import { EyeInvisibleOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
import React, { useState } from "react";
import { FieldValues } from "react-hook-form";
import { responseNotification } from "../../utils/notify";

function ForgotPassword({ refetch }: { refetch?: any }) {
  const [loading, setLoading] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [sendOtp, setSendOtp] = useState(true);
  const [form] = useForm();

  const onSubmit = async (data: FieldValues) => {
    const newPassword = data?.confirm;
    const otp = data?.otp;
    if (sendOtp) {
      const res = await fetch(`${process.env.REACT_APP_AUTH_API}/otp/send`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mobileNumber: `+88${mobileNumber?.replace("+88", "")}`,
        }),
      });

      const response1 = await res.json();

      if (response1?.statusCode === 200) {
        setSendOtp(false);
        responseNotification(
          response1?.message?.replace("Operation", "Otp sent"),
          "success"
        );
      }
    } else {
      setLoading(true);
      const res = await fetch(
        `${process.env.REACT_APP_AUTH_API}/jcpg/forget-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            mobileNumber: `+88${mobileNumber?.replace("+88", "")}`,
            newPassword: newPassword,
            otp: otp,
          }),
        }
      );

      const response2 = await res.json();
      if (response2?.statusCode === 200) {
        setLoading(false);
        refetch();
        responseNotification(
          response2?.message?.replace("Operation", "Password Change"),
          "success"
        );
        form.resetFields();
      } else {
        responseNotification(
          response2?.message?.replace("Operation", "Password Change"),
          "warning"
        );
      }
    }
  };

  // password show and hide
  const toggleShowPassword = () => {
    const password = document.querySelector("#fPassword") as any;
    const type =
      password.getAttribute("type") === "password" ? "text" : "password";
    password.setAttribute("type", type);
    password.classList.toggle("anticon-eye-invisible");
  };

  return (
    <Form onFinish={onSubmit} layout="vertical" form={form} size="large">
      <Input type="hidden" name="remember" defaultValue="true" />
      {sendOtp ? (
        <Form.Item
          label="Mobile Number"
          name="mobileNumber"
          initialValue={"01"}
          rules={[
            {
              required: true,
              message: "Mobile Number is required!",
            },
            {
              validator: async (_, names) => {
                if (!names?.match(/(^(01){1}[3456789]{1}(\d){8})$/)) {
                  return Promise.reject(new Error("Enter valid mobile number"));
                }
              },
            },
          ]}
        >
          <Input
            className="w-full text-lg border-b border-gray-300 focus:outline-none focus:border-indigo-500"
            // prefix={<MobileOutlined />}
            placeholder="01XXXXXXXXX"
            maxLength={11}
            type="contact"
            onChange={(e: any) => setMobileNumber(e.target.value)}
          />
        </Form.Item>
      ) : (
        <>
          <Form.Item label="OTP" name="otp" id="otp">
            <Input
              className="w-full text-lg h-10 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
              placeholder="Enter your OTP"
              id="otp"
              autoComplete={"off"}
            />
          </Form.Item>
          <Form.Item label="Password" name="fPassword">
            <Input
              className="w-full text-lg h-10 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
              // prefix={<UnlockOutlined />}
              suffix={
                <EyeInvisibleOutlined id="ddd" onClick={toggleShowPassword} />
              }
              placeholder="Enter your password"
              type="password"
              maxLength={11}
              id="fPassword"
            />
          </Form.Item>

          <Form.Item
            name="confirm"
            label="Confirm Password"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("fPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password maxLength={11} placeholder="Re-Type Password" />
          </Form.Item>
        </>
      )}
      <div className="mt-6">
        <Button
          htmlType="submit"
          //   size="large"
          //   block
          loading={loading}
          disabled={loading}
          className="bg-red-600 text-gray-100 tracking-wide
                                font-bold font-display focus:outline-none 
                                focus:shadow-outline hover:bg-red-600 hover:text-white
                                shadow-lg border-none rounded-md"
          style={{ background: "#CD113B" }}
        >
          Continue
        </Button>
      </div>
    </Form>
  );
}
export default ForgotPassword;
