import React from "react";
import ProfileUpdate from "../components/Layouts/ProfileUpdate";
import MainLayout from "../components/Layouts";

const ProfileUpdatePage = () => {
  return (
    <MainLayout>
      <ProfileUpdate />
    </MainLayout>
  );
};

export default ProfileUpdatePage;
