import { Button, Divider, Form, Input, Tooltip, Upload } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useState } from "react";
import { FieldValues } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../../redux/auth/authAction";
import { responseNotification } from "../../utils/notify";
import { ImageUploader } from "../common";
import { QuestionCircleOutlined } from "@ant-design/icons";

function CompanySignup() {
  const { type } = useSelector((state) => (state as any)?.authReducer);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [form] = useForm();
  const dispatch = useDispatch();
  const [generatedImgUrl, setGeneratedImgUrl] = useState<string>();
  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();

  const onSubmit = async (data: any) => {
    setLoading(true);
    const readyData = data && {
      address: data.address,
      email: data.email,
      industry: data.industry,
      ipnUrl: data.ipnUrl,
      name: data.name,
      shortName: data.shortName,
      mobileNumber: `+88${data?.mobileNumber?.replace("+88", "")}`,
      password: data?.password,
      confirmPassword: data?.confirmPassword,
    };

    await fetch(`${process.env.REACT_APP_RIDER_API}/jcpg/registration`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(readyData),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification("Registation Successfully", "success");
          form.resetFields();
          navigate("/");
          //router.replace('/login')
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  return (
    <div>
      <div className="w-full block py-6">
        <div
          className=" bg-indigo-100 lg:bg-white flex justify-center lg:justify-start lg:px-14 w-full
          "
        >
          <div className="cursor-pointer flex items-center">
            <img
              className="mx-auto h-12 w-auto"
              src="https://admin.jachai-staging.com/static/media/logo.1c44a2d1.svg"
              alt="jachai.com"
            />
          </div>
        </div>
        <div className="mt-10 px-10 sm:px-24 md:px-30 lg:px-12 lg:mt-16 xl:px-16 xl:max-w-4xl block mx-auto">
          <h2
            className="text-2xl text-slate-900 font-display font-light lg:text-center xl:text-2xl
                    mb-4 capitalize text-center"
          >
            Create Your Account
          </h2>

          <div className="mt-12 shadow-md p-6 my-8 rounded-md mx-auto">
            <Form
              onFinish={onSubmit}
              layout="vertical"
              form={form}
              size="large"
            >
              <Input type="hidden" name="remember" defaultValue="true" />
              <div className="grid grid-cols-2 gap-4">
                <Form.Item label="Name" id="name" name="name">
                  <Input
                    className="w-full text-lg border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                    type="text"
                    placeholder="Enter Your Name"
                  />
                </Form.Item>
                <Form.Item label="Short Name" id="shortName" name="shortName">
                  <Input
                    className="w-full text-lg border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                    type="text"
                    placeholder="Enter Your Short Name"
                  />
                </Form.Item>
                <Form.Item label="Email" id="email" name="email">
                  <Input
                    className="w-full text-lg border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                    type="text"
                    placeholder="Enter Your Email"
                  />
                </Form.Item>

                <Form.Item
                  label="Mobile Number"
                  id="mobileNumber"
                  name="mobileNumber"
                >
                  <Input
                    className="w-full text-lg border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                    type="number"
                    placeholder="Mobile Number"
                  />
                </Form.Item>
                <Form.Item label="Industry" id="industry" name="industry">
                  <Input
                    className="w-full text-lg border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                    type="text"
                    placeholder="Industry"
                  />
                </Form.Item>

                <Form.Item label="Address" id="address" name="address">
                  <Input
                    className="w-full text-lg border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                    type="text"
                    placeholder="Address"
                  />
                </Form.Item>
                <Form.Item label="IPN URL" id="ipnUrl" name="ipnUrl">
                  <Input
                    className="w-full text-lg border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                    type="text"
                    placeholder="IPN URL"
                  />
                </Form.Item>

                <ImageUploader
                  imgUrl={generatedImgUrl || ""}
                  startUpload={startUpload}
                  setStartUpload={(val: any) => setStartUpload(val)}
                  setGeneratedImgUrl={(url: any) => setGeneratedImgUrl(url)}
                  // crop={1}
                />

                <Form.Item label="Password" id="password" name="password">
                  <Input
                    className="w-full text-lg border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                    type="password"
                    placeholder="Enter your password"
                  />
                </Form.Item>
                <Form.Item
                  label="Confirm Password"
                  id="confirmPassword"
                  name="confirmPassword"
                >
                  <Input
                    className="w-full text-lg border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                    type="password"
                    placeholder="Enter Confirm Password"
                  />
                </Form.Item>
              </div>

              <Link
                to="/"
                className="text-xs font-display font-semibold text-indigo-600 hover:text-indigo-800 cursor-pointer m-0 p-0"
              >
                Login
              </Link>
              {/* <Link
                  to="#"
                  className="text-xs font-display font-semibold text-indigo-600 hover:text-indigo-800 cursor-pointer m-0 p-0"
                >
                  Forgo?
                </Link> */}
              <div className="mt-10 w-full">
                <Button
                  htmlType="submit"
                  shape="round"
                  size="large"
                  block
                  loading={loading}
                  className="bg-indigo-500 text-gray-100 tracking-wide
                                font-bold font-display focus:outline-none 
                                focus:shadow-outline hover:bg-indigo-600 hover:text-white
                                shadow-lg"
                >
                  {"Signup"}
                </Button>
              </div>
            </Form>
            {/* <div className="mt-12 text-sm font-display font-semibold text-gray-700 text-center">
                Don&apos;t have an account ?{" "}
                <Link
                  to="#"
                  className="cursor-pointer text-indigo-600 hover:text-indigo-800"
                >
                  Sign up
                </Link>
              </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
export default CompanySignup;
