import { Button, Form, Input } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../utils/notify";
import styles from "../../styles/tailwind/Add.module.css";
import { ImageUploader, SubmitResetBtn } from "../common";
import BreadCrumb from "../Layouts/Breadcrumb";
import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const UpdateProfile = ({ visibleData, onCloseMethod }: any) => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const initialState = useSelector((state) => (state as any)?.authReducer);
  const [generatedImgUrl, setGeneratedImgUrl] = useState<string>();
  const navigate = useNavigate();
  const route = useParams();
  const merchantsId = initialState?.merchantIdInAuth;
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [merchantData, setMerchantData] = useState<any>(undefined);
  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const fetchMerhantDetails = useCallback(
    (merchantsId: any) => {
      if (merchantsId) {
        try {
          setMerchantData({ loading: true, data: null });
          axios
            .get(
              `${process.env.REACT_APP_RIDER_API}/jcpg/details?jcpgMerchantId=${merchantsId}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
              }
            )
            .then((data) => {
              if (data.status === 200) {
                setMerchantData({
                  loading: false,
                  data: data?.data?.jcPgMerchant,
                });
              } else {
                setMerchantData({ loading: false, data: null });
                responseNotification(
                  data.statusText || "something went wrong",
                  "error"
                );
              }
            })
            .catch((err) => {
              setMerchantData({ loading: false, data: null });
              console.log(err);
            });
        } catch (error) {
          setMerchantData({ loading: false, data: null });
          console.log(error, "error");
        }
      }
    },
    [merchantsId]
  );

  useEffect(() => {
    fetchMerhantDetails(merchantsId);
  }, [fetchMerhantDetails]);

  useEffect(() => {
    if (startUpload === "Uploaded") {
      form.submit();
    }
  }, [form, startUpload]);
  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      address: data.address,
      email: data.email,
      industry: data.industry,
      ipnUrl: data.ipnUrl,
      mobileNumber: data.mobileNumber,
      name: data.name,
      shortName: data.shortName,
      image: generatedImgUrl ? generatedImgUrl : data?.image,
      password: data.password,
    };

    if (merchantData) {
      await fetch(`${process.env.REACT_APP_RIDER_API}/jcpg`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
          id: merchantData?.data?.id,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Profile Updated Successfully", "success");
            form.resetFields();
            navigate("/");
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const resetData = () => {
    form?.resetFields();
    setStartUpload(undefined);
  };

  useEffect(() => {
    if (merchantData) {
      form?.resetFields();
      setGeneratedImgUrl(merchantData?.data?.image);
    }
  }, [merchantData]);
  return (
    <>
      <BreadCrumb
        title={merchantData?.data ? "Edit Profile" : "Add Merchant"}
      />
      <div className="content-body bg-white rounded-2xl p-4 shadow">
        <Form
          name="control-hooks"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className={styles.formStyles}
          onFinish={onSubmit}
          form={form} // like ref
          layout="vertical"
          initialValues={{
            ...merchantData?.data,
          }}
        >
          <Input type="hidden" name="remember" defaultValue="true" />
          <div className="grid grid-cols-2 gap-4">
            <Form.Item
              hasFeedback
              label="Merchant Name"
              rules={[
                {
                  required: true,
                  message: "Name is Required!",
                },
              ]}
              name="name"
            >
              <Input
                disabled={merchantData?.data}
                id="name"
                type="text"
                placeholder="Enter Merchant Name"
                className="w-full text-lg border-b border-gray-300 focus:outline-none focus:border-indigo-500"
              />
            </Form.Item>
            <Form.Item label="Short Name" id="shortName" name="shortName">
              <Input
                className="w-full text-lg border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                type="text"
                placeholder="Enter Your Short Name"
              />
            </Form.Item>
            <Form.Item label="Email" id="email" name="email">
              <Input
                className="w-full text-lg border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                type="text"
                placeholder="Enter Your Email"
              />
            </Form.Item>
            <Form.Item
              hasFeedback
              label="Mobile Number"
              rules={[
                {
                  required: !merchantData?.data?.mobileNumber,
                  message: "Mobile Number is required!",
                },
                {
                  validator: async (_, names) => {
                    if (
                      !merchantData?.data?.mobileNumber &&
                      !names?.match(/(^(01){1}[3456789]{1}(\d){8})$/)
                    ) {
                      return Promise.reject(
                        new Error("Enter valid mobile number")
                      );
                    }
                  },
                },
              ]}
              name="mobileNumber"
              initialValue={merchantData?.data?.mobileNumber || "01"}
            >
              <Input
                disabled={merchantData?.data}
                id="mobileNumber"
                type="contact"
                addonBefore={"+88"}
                placeholder="Enter Mobile Number"
                defaultValue={merchantData?.data?.mobileNumber}
                value={merchantData?.data?.mobileNumber}
              />
            </Form.Item>

            <Form.Item label="Industry" id="industry" name="industry">
              <Input
                className="w-full text-lg border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                type="text"
                placeholder="Industry"
              />
            </Form.Item>

            <Form.Item label="Address" id="address" name="address">
              <Input
                className="w-full text-lg border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                type="text"
                placeholder="Address"
              />
            </Form.Item>
            <Form.Item label="IPN URL" id="ipnUrl" name="ipnUrl">
              <Input
                className="w-full text-lg border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                type="text"
                placeholder="IPN URL"
              />
            </Form.Item>
            <ImageUploader
              imgUrl={generatedImgUrl || ""}
              startUpload={startUpload}
              setStartUpload={(val: any) => setStartUpload(val)}
              setGeneratedImgUrl={(url: any) => setGeneratedImgUrl(url)}
            />
          </div>
          <SubmitResetBtn
            onClickSubmit={(e) => {
              e.preventDefault();
              setStartUpload("Uploading");
            }}
            onClickReset={() => {
              form?.resetFields();
              setGeneratedImgUrl(undefined);
              setStartUpload(undefined);
            }}
            disabled={loading || !!error}
            loading={loading}
          />
        </Form>
      </div>
    </>
  );
};

export default UpdateProfile;
